import React from "react"
import "../../styles/global.scss"
import Navbar from "./Navbar"
import Footer from "../../components/Footer"

const Layout = ({ children }) => {
  return (
    <>
      <body className="has-navbar-fixed-top has-lightest-background-color">
        <Navbar />
        {children}
        <Footer />
      </body>
    </>
  )
}

export default Layout
